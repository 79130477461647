.Footer{
  display: flex;
  flex-direction: row;
  /* height: 10vh; */
  align-content: flex-end;
  bottom:0;
  width:100%;
  /* height:60px;   Height of the footer */
  justify-content: flex-end;
  align-self: flex-end;
}

.FooterText{
  margin-right: 16px;
  margin-top: 22px;
  align-items: right;
  color: greenyellow;
  opacity: 50%;
  /* align-self: ; */
}

.spaceship{
  height: auto;
  width: 40px;
  margin-right: 50px;
  /* margin-top: 8px; */
  transform: rotate(90deg);
}
