#gameWindow{
  position: relative;
  height: 400px;
  width: 550px;
  background-color: rgb(10, 8, 8);
  align-self: center;
}

#gameWindowContainer{
  width:100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: url("../../../public/imgs/pixel\ city\ background.gif");
  background-size: cover;
  height: 100%;
  border: solid 2px gray;
}

.item {
  width: 10em;
  text-align: center;
  align-self: center;
}
  

.meteor {
  /* top: 27.55vh; */
  /* right: 17.22vw;
  width: 5.2vw; */
  transition: 1s;
  /* animation: fly 5s linear infinite; */
  /* transform: translateX(100%); */
}

@keyframes fly {
  0%{
      transform: translate(0,50vh);opacity: .50;
  }
  50%{
      transform: translate(50vw,0);opacity: 1;
  }
  100%{
      transform: translate(0,50vh);opacity: .50;
  }
}

.hide{
  display: none;
}