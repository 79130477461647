@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.App {

  font-family: 'VT323', monospace ;
  color: white;
  background-color: black;
  background-image: url("../public/imgs/BackgroundStars.gif");
  min-height: 100vh;
}

.ContentBody{
  min-height: 800px;
  height: 90vh;
  /* height: 100%; */
}