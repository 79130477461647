/* body {
  text-align: center;
  padding: 30px;
} */

.hide{
  display: none;
}

.eightbit-btn-fullscreen {
  background: #92cd41;
  display: inline-block;
  position: relative;
  text-align: center;
  font-size: 15px;
  font-family: 'VT323', monospace !important;
  text-decoration: none;
  color: rgb(0, 0, 0);
  box-shadow: inset -4px -4px 0px 0px #4aa52e;
  /* margin-bottom: 13px !important; */
  /* margin-left:500px !important; */
  /* right:0;
  margin: auto; */
  /* align-self: flex-end; */

  /* margin-top: 15px !important; */

}
.eightbit-btn:hover, .eightbit-btn:focus {
  background: #76c442;
  box-shadow: inset -6px -6px 0px 0px #4aa52e;
}
.eightbit-btn:active {
  box-shadow: inset 4px 4px 0px 0px #4aa52e;
}
.eightbit-btn:before, .eightbit-btn:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
}
.eightbit-btn:before {
  top: -6px;
  left: 0;
  border-top: 6px black solid;
  border-bottom: 6px black solid;
}
.eightbit-btn:after {
  left: -6px;
  top: 0;
  border-left: 6px black solid;
  border-right: 6px black solid;
}

.eightbit-btn--proceed {
  background: #f7d51d;
  box-shadow: inset -4px -4px 0px 0px #e59400;
}
.eightbit-btn--proceed:hover, .eightbit-btn--proceed:focus {
  background: #f2c409;
  box-shadow: inset -6px -6px 0px 0px #e59400;
}
.eightbit-btn--proceed:active {
  box-shadow: inset 4px 4px 0px 0px #e59400;
}
/* 
*,
*:before,
*:after {
  box-sizing: border-box;
} */

