.score{
  color:white;
  margin-left: 100px;
  font-size: 25px;
}

.position{
  color:white;
  font-size: 25px;
  margin-left: 30%;
  max-width: 20px;
}

.scoreContainer{
  display: flex;
  flex-direction: row;

}

.scoreTitle{
  margin-left:45%;
  font-size: 20px;
  /* margin-top: 2%; */
  margin-bottom: 2%;

}
